<template>
  <div style="position:relative">
    <div class="panel demoWrapper">
      <div class="basic-info">
        <div class="basic-info-top">Basic info</div>
        <div class="basic-info-con">
          <el-form
            ref="formLabelAlign"
            :model="formLabelAlign"
            :inline="true"
            label-width="200px"
            class="demo-ruleForm"
          >
            <el-form-item label="Enterprise Number:" prop="id">
              <span>
                {{ formLabelAlign.id }}
              </span>
            </el-form-item>

            <el-form-item label="Enterprise Name:" prop="enterpriseName">
              <Tooltip
                :content="formLabelAlign.enterpriseName"
                class="wid190"
                ref-name="tooltipOver"
              />
            </el-form-item>

            <el-form-item label="Enterprise Type:" prop="enterpriseType">
              <span v-if="formLabelAlign.enterpriseType == 1">
                listed company
              </span>
              <span v-if="formLabelAlign.enterpriseType == 2">
                private enterprise
              </span>
              <span v-if="formLabelAlign.enterpriseType == 3">
                state-owned business
              </span>
              <span v-if="formLabelAlign.enterpriseType == 4">
                cooperative partner
              </span>
              <span v-if="formLabelAlign.enterpriseType == 5">
                foundation
              </span>
              <span v-if="formLabelAlign.enterpriseType == 6">
                social institution
              </span>
              <span v-if="formLabelAlign.enterpriseType == 7">
                governmental agencies
              </span>
              <span v-if="formLabelAlign.enterpriseType == 8">
                On the other
              </span>
            </el-form-item>

            <el-form-item label="License Type:" prop="licenseType">
              <span v-if="formLabelAlign.licenseType == 0">
                Business License
              </span>
            </el-form-item>

            <el-form-item label="License Number:" prop="licenseNumber ">
              <div style="width:70px" class="form-item">
                <span>
                  {{ formLabelAlign.licenseNumber }}
                </span>
              </div>
            </el-form-item>

            <el-form-item label="Organization Code:" prop="licenseNumber ">
              <div class="form-item">
                <span>
                  {{ formLabelAlign.organizationCode }}
                </span>
              </div>
            </el-form-item>

            <el-form-item label="Corporation Name:" prop="corporationName ">
              <div class="form-item">
                <span>
                  {{ formLabelAlign.corporationName }}
                </span>
              </div>
            </el-form-item>

            <el-form-item
              label="Corporation Credentials Type:"
              prop="credentialsType "
            >
              <div class="form-item">
                <span v-if="formLabelAlign.credentialsType == 1">
                  Passport
                </span>
                <span v-if="formLabelAlign.credentialsType==2">Other</span>
                <span v-if="formLabelAlign.credentialsType==0">ID Card</span>
              </div>
            </el-form-item>

            <el-form-item label="Corporation Credentials Number:">
              <div class="form-item">
                <span>
                  {{ formLabelAlign.credentialsNumber }}
                </span>
              </div>
            </el-form-item>

            <el-form-item label="Taxpayer Identification Number:">
              <div class="form-item">
                <font v-text="formLabelAlign.invoiceTaxpayerNumber" />
              </div>
            </el-form-item>

            <el-form-item label="Enterprise Mail:">
              <div class="form-item">
                <font v-text="formLabelAlign.enterpriseEmail" />
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="panel demoWrapper button_add">
      <el-form v-model.trim="formLabelAlign1">
        <el-form-item label="Audit Result" prop="radio">
          <el-radio-group
            v-model.trim="formLabelAlign1.radio"
            @change="agreeChange"
          >
            <el-radio :label="1">Passed</el-radio>
            <el-radio :label="2">Rejected</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="Audit Opinion" prop="remark">
          <textarea
            v-model.trim="remark"
            placeholder="please enter "
            class="inputTextarea"
          />
        </el-form-item>
        <div style="heigth:450px" />
      </el-form>

      <div class="button-add-r">
        <el-button
          type="primary"
          class="submitted"
          style="padding: 0 20px;"
          @click="Submit"
        >
          Submit
        </el-button>
        <el-button type="primary" style="padding: 0 20px;" @click="back">
          Back
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { examineBusiness,getEnterpriseInfo } from '@/api/enterprise/enterprise';
import Tooltip from '../../components/tooltipOver' 

export default {
  components:{
    Tooltip
  },
  data() {
    return {
      // 添加企业信息数据
      input123: '',
      isShowTooltip: true,
      formLabelAlign: {},
      // 层级选择器
      value: '',
      labelPosition: 'left',
      labelPosition1: 'left',
      // 基本信息数据
      formLabelAlign1: {
        radio: 0
      },
      remark: '',
      id:this.$route.query.id
    };
  },
  watch: {
    $route() {
      this.formLabelAlign = this.$route.query.row;
    }
  },
  created() {
    this.info();
  },
  mounted() {},
  methods: {
    //  返回上一级
    back() {
      this.$router.go(-1);
    },
    info() {
      getEnterpriseInfo(this.id).then(res=>{
        if(res.code==200){
          this.formLabelAlign=res.data;
          let nameState = 0;
          if (res.data.baseInfoAuditState == 1) {  // 回显通过
            nameState = 1;
          } else if (res.data.baseInfoAuditState == 2) {  // 回显拒绝
            nameState = 2;
          } else { // 待审核
            nameState = 0;
          }
          this.formLabelAlign1.radio = nameState;
          this.remark = res.data.remark || '';
        }
      })
    },
    agreeChange(val) {},
    Submit() {
      //  if (this.formLabelAlign.state==1) {
      //    //console.log(this.formLabelAlign.state)
      //    return   this.$message("该数据状态无法审核");
      //  }
      if(this.formLabelAlign1.radio<1){
        this.$message(
          {
            message: 'Please select audit result',
            type: 'error'
          });
        return
      }
      if(this.formLabelAlign1.radio==2 && !this.remark){
        return this.$message({message:'Please fill in the reason for rejection'})
      }
      let arr = {
        id: Number(this.formLabelAlign.id),
        modifyEnterprise: 1,
        modifyInvoice: 0,
        modifyBank: 0,
        reviewStatus: this.formLabelAlign1.radio, //审核通过
        // creator: 11111,
        remark: this.remark
      };
      let obj = JSON.stringify(arr);
      examineBusiness(obj).then(res => {
        if (res.code == 200) {
          this.$message({ message: 'Operation succeeded', type: 'success' });
          this.$router.push('enterprise');
        }else{
          this.$message({ message: res.msg, type: 'error' });
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.demoWrapper {
  .basic-info {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 11px;
    // height: 513px;
    height: 560px;
    border-radius: 8px;
    .basic-info-top {
      height: 67px;
      line-height: 67px;
      font-size: 20px;
      font-family: Arial;
      font-weight: normal;
      color: rgba(36, 37, 38, 1);
    }
    .basic-foot {
      height: 80px;
      // display: flex;
      padding-top: 39px;
    }
  }
  .invoice-info {
    margin-top: 20px;
  }
}
.button_add {
  position: fixed;
  height: 300px;
  width: 83.5%;
  bottom: 4%;
  padding-top: 20px;
  box-shadow: 3px 5px 9px #000;
  clear: both;
  .button-add-r {
    width: 300px;
    position: relative;
    bottom: 230px;
    left: 80%;
  }
}
.over-flow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.el-form-item {
  margin-bottom: 30px;
}

::v-deep .el-form-item__content {
  width: 200px;
}
/deep/.el-form-item {
  margin-left: 10px !important;
}
.form-item {
  width: 200px;
}
.el-form-item:nth-of-type(8) {
  /deep/.el-form-item__label {
    line-height: 20px !important;
  }
}
.el-form-item:nth-of-type(9) {
  /deep/.el-form-item__label {
    line-height: 20px !important;
  }
}
.el-form-item:nth-of-type(10) {
  /deep/.el-form-item__label {
    line-height: 20px !important;
  }
}
.inputTextarea {
  outline: none;
  width: 500px;
  height: 100px;
  margin-bottom: 20px;
}
/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}
</style>
